import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './components/signin/signin.component';
import { MsalAuthGuard  } from './shared/services/msal-auth.guard';

const routes: Routes = [
  {
    path: 'signin',
    component: SignInComponent
  },
  {
    path: '',
    redirectTo: '/kudos',
    pathMatch: 'full'
  },
  {
    path: 'kudos',
    loadChildren: () => import('./kudos/kudos.module').then(m => m.kudosModule),
    data: {title: 'kudos'},
    canActivate: [MsalAuthGuard ]
  },
  {
    path: '**',
    redirectTo: '/kudos',
    data: {title: 'Not Found'},
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }